<template>
    <div class="d-flex flex-row">

        <!-- LEFT -->
        <div class="o365-header-pinned-left" v-if="gridControl.columns.hasPinnedLeftColumns"
            :style="[
                {'min-width': gridControl.columns.leftPinnedWidth + 'px'},
                {'max-width': gridControl.columns.leftPinnedWidth + 'px'},
                {'width': gridControl.columns.leftPinnedWidth + 'px'}
            ]">
            <slot name="bandrows"></slot>

            <div :class="rowClass">
                <template v-for="col in gridControl.columns.leftColumns" :key="col.id">
                    <template v-if="!col.hide && col.pinned === 'left'">
                        <slot name="headercell" :col="col">
                            <BaseHeaderCell :col="col"/>
                        </slot>
                    </template>
                </template>
            </div>
        </div>

        <!-- CENTER -->
        <div class="o365-header-viewport">
            <div class="o365-grid-container" :style="{'width': gridControl.columns.centerWidth + 'px'}">
                <slot name="bandrows"></slot>

                <div :class="rowClass">
                    <template v-for="col in gridControl.columns.centerColumns" :key="col.id">
                        <template v-if="!col.hide && col.pinned == null">
                            <slot name="headercell" :col="col">
                                <BaseHeaderCell :col="col"/>
                            </slot>
                        </template>
                    </template>
                </div>
            </div>
        </div>

        <!-- RIGHT -->
        <div class="o365-header-pinned-right o365-header-pinned-right-headers" v-if="gridControl.columns.hasPinnedRightColumns"
            :style="[
                {'min-width':gridControl.columns.rightPinnedWidth + 'px'},
                {'max-width':gridControl.columns.rightPinnedWidth + 'px'},
                {'width':gridControl.columns.rightPinnedWidth + 'px'}
            ]">

            <slot name="bandrows"></slot>

                <div :class="rowClass">
                <template v-for="col in gridControl.columns.rightColumns" :key="col.id">
                    <template v-if="!col.hide && col.pinned === 'right'">
                        <slot name="headercell" :col="col">
                            <BaseHeaderCell :col="col"/>
                        </slot>
                    </template>
                </template>
            </div>
        </div>

    </div>
</template>

<script lang="ts">
export interface IBaseHeaderRowProps {
    rowClass?: string;
};
</script>

<script setup lang="ts">
import useBaseGridControl from 'o365.vue.composables.Grid.BaseControlInject.ts';
import { defineAsyncComponent } from 'vue';

// Async components
const BaseHeaderCell = defineAsyncComponent(() => import('o365.vue.components.Grid.BaseHeaderCell.vue'));

const props = withDefaults(defineProps<IBaseHeaderRowProps>(), {
    rowClass: 'o365-header-row',
});

const gridControl = useBaseGridControl();
</script>